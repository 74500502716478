import axios from "axios";
import get from 'lodash.get'
import { sanitizeUrl } from '@braintree/sanitize-url'

import store from "@/store";

// interceptor
axios.interceptors.request.use(function(config) {
  config.url = sanitizeUrl(config.url);
  return config;
})

export default (secured = true) => {
  const uniqueId = new Date().getTime().toString(36) + '-' + Math.random().toString(36).substr(2, 9);
  const deviceId = window.localStorage.getItem("asomsDeviceId");
  if(!deviceId) {
    window.localStorage.setItem("asomsDeviceId", uniqueId);
  } 
  if (secured) {
    return axios.create({
      headers: {
        "Authorization": `bearer ${get(store, 'state.auth.token')}`,
        "x-asoms-device-id": deviceId || uniqueId
      }
    });
  } else {
    return axios.create();
  }
} 