import { createRouter, createWebHashHistory } from "vue-router";
import authRoutes from "./authRoutes";
import userManagementRoutes from "./userManagementRoutes";
import manpowerRoutes from "./manpowerRoutes";
import cashManagementRoutes from "./cashManagementRoutes";
import inventoryRoutes from "./inventoryRoutes";
import faultManagementRoutes from "./faultManagementRoutes";
import opsCommsRoutes from "./opsCommsRoutes";
import offlineRoutes from "./offlineRoutes";
import notificationsRoutes from "./notificationsRoutes";
import authenticateRoute from "./helpers/authenticateRoute";
import homeRedirect from "./helpers/homeRedirect";
import loadNotification from "./helpers/loadNotification";
import tourist from "./tourist.routes";
import shiftHandover from "./shiftHandoverRoutes";
// import atomsRoutes from "./atomsRoutes";
import adminConfig from "./adminConfigurationRoutes";
import loadCashManagementTransactions from './helpers/loadCashManagementTransactions';
import refreshTokenBeforeEnter from "./helpers/refreshTokenBeforeEnter";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    beforeEnter: homeRedirect,
  },
  ...authRoutes,
  ...userManagementRoutes,
  ...manpowerRoutes,
  ...cashManagementRoutes,
  ...inventoryRoutes,
  ...faultManagementRoutes,
  ...opsCommsRoutes,
  ...offlineRoutes,
  ...notificationsRoutes,
  ...tourist,
  ...shiftHandover,
  // ...atomsRoutes,
  ...adminConfig,
  {
    path: "/stations",
    name: "Stations",
    component: () =>
      import(/* webpackChunkName: "stations_index" */ "../views/Stations.vue"),
    meta: {
      title: "screens.Stations.pageTitle",
    },
  },
  {
    path: "/popular-links",
    name: "Popular Links",
    component: () =>
      import(/* webpackChunkName: "popular_links" */ "../views/PopularLinks.vue"),
    meta: {
      title: "screens.PopularLinks.pageTitle",
    },
  },
  {
    path: "/change-station",
    name: "Change Current Station",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/ChangeSelectedStation.vue"
      ),
    meta: {
      title: "screens.ChangeSelectedStation.pageTitle",
    },
  },
  {
    path: "/403",
    name: "403 Forbidden",
    component: () =>
      import(
        /* webpackChunkName: "403_Forbidden" */ "../views/403_Forbidden.vue"
      ),
    meta: {
      title: "screens.403_Forbidden.pageTitle",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Error",
    component: () =>
      import(/* webpackChunkName: "error" */ "../views/Error.vue"),
    meta: {
      title: "screens.Error.pageTitle",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    let ele = document.getElementById("AsomLayout__MainContent");
    if (ele) ele.scrollTo(0, 0);
  },
});

router.beforeEach((to, from, next) => {
  authenticateRoute(to, from, next);
  loadNotification();
  loadCashManagementTransactions(to);
  refreshTokenBeforeEnter(to)
});

export default router;
