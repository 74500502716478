import {
  ROSTER_LIST,
  ROSTER_DETAILS,
  SUGGEST_REPLACEMENTS,
  BLOCK_LEAVE,
  OT_LIST,
  OT_CREATE,
  OT_REMOVE,
  LIST_AVAIL_CHANGES,
  MARK_AVAIL,
  REMOVE_AVAIL_CHANGE_REQUEST,
  MUTUAL_EXCHANGE,
  ASSIGN_OT,
  ASSIGN_SPARE,
  ASSIGN_EXTEND_OT,
  GET_LINE_DWS,
  EDIT_ROSTER,
  ROSTER_GAP,
  GET_CURRENT_ROSTER,
  POST_PLANNED_OT,
  MARK_OT_UNAVAIL,
  EXTEND_VACANT,
  LIST_CONFLICTS,
  LIST_OTHOURS,
  LIST_MOMREGULATIONS,
  LIST_LEAVES,
  LIST_PENDINGREPLACEMENTS,
  CANCEL_COVERING_SHIFT,
  REMOVE_BLOCK_LEAVE,
  REMOVE_OT_UNAVAIL,
  CANCEL_AVAILABILITY,
  COVERING_SHIFT,
  MANPOWER_HEALTH_CHECK,
  LIST_ROLES,
  STATION_LIST,
  GET_NEXT_ROSTER,
  COVERING_SHIFT_SAME_STATION,
} from "../constants/apis";
import createAxiosInstance from "./createAxiosInstance";
import { successHandler, errorHandler } from "./serviceHandlers";
import { getURLSearchParams } from "./service.helpers";

export async function manpowerHealthCheck() {
  try {
    const response = await createAxiosInstance(true).get(MANPOWER_HEALTH_CHECK);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getNextRoster({ stationId, officerId, planDate }) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        officerId,
        planDate,
      },
    });
    const response = await createAxiosInstance(true).get(GET_NEXT_ROSTER, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function listRoles() {
  try {
    const response = await createAxiosInstance(true).get(LIST_ROLES);
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getStationsList({
  lineIds,
  groupIds,
  zoneIds,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        lineIds,
        groupIds,
        zoneIds,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(STATION_LIST, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getCurrentRoster() {
  try {
    const response = await createAxiosInstance(true).get(GET_CURRENT_ROSTER);
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getRosterList({
  officers,
  dateFrom,
  dateTo,
  lineId,
  stationId,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        officers,
        dateFrom,
        dateTo,
        lineId,
        stationId,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(ROSTER_LIST, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getRosterDetails(rosterId) {
  const params = getURLSearchParams({
    data: {
      rosterId,
    },
  });
  try {
    const response = await createAxiosInstance(true).get(ROSTER_DETAILS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getLineDws({ lineId, officerId }) {
  try {
    const response = await createAxiosInstance(true).post(GET_LINE_DWS, {
      lineId: lineId,
      officerId: officerId,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getReplacementSuggestions({
  officerId,
  stationId,
  lineId,
  searchIn = 0,
  shiftStatus = [],
  dateTimeFrom,
  dateTimeTo,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        officerId,
        stationId,
        lineId,
        searchIn,
        shiftStatus,
        dateTimeFrom,
        dateTimeTo,
      },
    });
    const response = await createAxiosInstance(true).get(SUGGEST_REPLACEMENTS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getListOfConflicts({
  stationId,
  lineId,
  officers,
  datefrom,
  dateto,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        officers,
        datefrom,
        dateto,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(LIST_CONFLICTS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getListOfOTHours({
  stationId,
  lineId,
  officers,
  datefrom,
  dateto,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        officers,
        datefrom,
        dateto,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(LIST_OTHOURS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getListOfMOMRegulations({
  stationId,
  lineId,
  officers,
  datefrom,
  dateto,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        officers,
        datefrom,
        dateto,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(LIST_MOMREGULATIONS, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getListOfLeaveReports({
  stationId,
  lineId,
  officers,
  datefrom,
  dateto,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        officers,
        datefrom,
        dateto,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(LIST_LEAVES, {
      params: params,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function getListOfPendingReplacements({
  stationId,
  lineId,
  officers,
  datefrom,
  dateto,
  skip = 0,
  take = 100,
  search,
}) {
  try {
    const params = getURLSearchParams({
      data: {
        stationId,
        lineId,
        officers,
        datefrom,
        dateto,
        skip,
        take,
        search,
      },
    });
    const response = await createAxiosInstance(true).get(
      LIST_PENDINGREPLACEMENTS,
      {
        params: params,
      }
    );
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function blockLeave(formData) {
  try {
    const response = await createAxiosInstance(true).post(
      BLOCK_LEAVE,
      formData
    );
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}

export async function validateMutualExchange({ officer1, officer2 }) {
  try {
    const response = await createAxiosInstance(true).post(MUTUAL_EXCHANGE, {
      officer1,
      officer2,
      justValidate: true,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createMutualExchange({ officer1, officer2, remarks }) {
  try {
    const response = await createAxiosInstance(true).post(MUTUAL_EXCHANGE, {
      officer1,
      officer2,
      remarks,
      justValidate: false,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getAvailabilityChanges({ skip = 0, take = 100 }) {
  try {
    const response = await createAxiosInstance(true).get(LIST_AVAIL_CHANGES, {
      params: { skip: skip, take: take },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function markAvailability({
  officerId,
  planDate,
  fromDateTime,
  toDateTime,
  notAvailableForWholeShift,
  remarks,
}) {
  try {
    const response = await createAxiosInstance(true).post(MARK_AVAIL, {
      officerId,
      planDate,
      fromDateTime,
      toDateTime,
      notAvailableForWholeShift,
      remarks,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function cancelAvailability({ rosterId }) {
  try {
    const response = await createAxiosInstance(true).post(CANCEL_AVAILABILITY, {
      rosterId,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function removeAvailabilityChangeRequest(changeId) {
  try {
    const response = await createAxiosInstance(true).post(
      REMOVE_AVAIL_CHANGE_REQUEST,
      {
        changeId: changeId,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getOTVolunteerList({ skip = 0, take = 100 }) {
  try {
    const response = await createAxiosInstance(true).get(OT_LIST, {
      params: { skip: skip, take: take },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function createOTVolunteer({
  officerId,
  dateFrom,
  dateTo,
  hours,
}) {
  try {
    const response = await createAxiosInstance(true).post(OT_CREATE, {
      officerId,
      dateFrom,
      dateTo,
      hours,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function removeOTVolunteer({ otVolunteerId }) {
  try {
    const response = await createAxiosInstance(true).post(OT_REMOVE, {
      otVolunteerId,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function removeBlockedLeave({ blockedLeaveId }) {
  try {
    const response = await createAxiosInstance(true).post(REMOVE_BLOCK_LEAVE, {
      blockedLeaveId,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function removeOTUnavailability({ otUnavailableId }) {
  try {
    const response = await createAxiosInstance(true).post(REMOVE_OT_UNAVAIL, {
      otUnavailabilityId: otUnavailableId,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function assignOT({
  replacingRosterId,
  officerId,
  stationId,
  lineId,
  planDate,
  shiftStartTime,
  shiftEndTime,
  dws,
  forced = false,
}) {
  try {
    const response = await createAxiosInstance(true).post(ASSIGN_OT, {
      replacingRosterId,
      officerId,
      stationId,
      lineId,
      planDate,
      shiftStartTime,
      shiftEndTime,
      dws,
      forced,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function assignSpare({
  replacingRosterId,
  officerId,
  stationId,
  lineId,
  planDate,
  dws,
  forced = false,
}) {
  try {
    const response = await createAxiosInstance(true).post(ASSIGN_SPARE, {
      replacingRosterId,
      officerId,
      stationId,
      lineId,
      planDate,
      dws,
      forced,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function assignExtendShift({
  officerId,
  planDate,
  coveringRosterId,
}) {
  try {
    const response = await createAxiosInstance(true).post(
      COVERING_SHIFT_SAME_STATION,
      {
        officerId,
        planDate,
        coveringRosterId,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function assignExtendedOT({
  priorOfficerId,
  priorOfficerShiftEnd,
  priorOfficerPlanDate,
  afterOfficerId,
  afterOfficerShiftStart,
  afterOfficerPlanDate,
  stationId,
  lineId,
  replacingRosterId,
  forced = false,
}) {
  try {
    let data = {
      stationId,
      lineId,
      replacingRosterId,
      forced,
    };
    if (priorOfficerId) {
      data.priorOfficer = {
        officerId: priorOfficerId,
        shiftEnd: priorOfficerShiftEnd,
        planDate: priorOfficerPlanDate,
      };
    }
    if (afterOfficerId) {
      data.afterOfficer = {
        officerId: afterOfficerId,
        shiftStart: afterOfficerShiftStart,
        planDate: afterOfficerPlanDate,
      };
    }
    const response = await createAxiosInstance(true).post(
      ASSIGN_EXTEND_OT,
      data
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getRosterGap(rosterId) {
  try {
    const response = await createAxiosInstance(true).post(ROSTER_GAP, {
      rosterId: rosterId,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function postPlannedOT({
  stationId,
  lineId,
  planDate,
  officerId,
  planStart,
  planEnd,
  otDurations = [],
  forced = false,
  remark,
}) {
  try {
    const response = await createAxiosInstance(true).post(POST_PLANNED_OT, {
      stationId: stationId,
      lineId: lineId,
      planDate: planDate,
      officerId: officerId,
      planStart: planStart,
      planEnd: planEnd,
      otDurations,
      forced,
      remark,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function markOTUnavailable({ 
  officerId, 
  dateFrom, 
  dateTo,
}) {
  try {
    const response = await createAxiosInstance(true).post(MARK_OT_UNAVAIL, {
      officerId: officerId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function getExtensionVacants(rosterId) {
  try {
    const response = await createAxiosInstance(true).get(EXTEND_VACANT, {
      params: { rosterId: rosterId },
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function coveringShift({
  officerId,
  planDate,
  originalStationId,
  coveringStationId,
}) {
  try {
    const response = await createAxiosInstance(true).post(COVERING_SHIFT, {
      officerId: officerId,
      planDate: planDate,
      originalStationId: originalStationId,
      coveringStationId: coveringStationId,
    });
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function cancelCoveringShift(rosterId) {
  try {
    const response = await createAxiosInstance(true).post(
      CANCEL_COVERING_SHIFT,
      {
        rosterId: rosterId,
      }
    );
    return successHandler(response);
  } catch (e) {
    return errorHandler(e);
  }
}

export async function editRoster({
  rosterId,
  officerId,
  stationId,
  planDate,
  dws,
  dwsText,
  planStart,
  planEnd,
  planHours,
  shiftIsOt = false,
  remarks,
  forced = false,
}) {
  try {
    const response = await createAxiosInstance(true).post(EDIT_ROSTER, {
      rosterId: rosterId,
      officerId: officerId,
      stationId: stationId,
      planDate: planDate,
      dws: dws,
      dwsText: dwsText,
      planStart: planStart,
      planEnd: planEnd,
      planHours: planHours,
      shiftIsOt: shiftIsOt,
      remarks: remarks,
      forced,
    });
    return successHandler(response);
  } catch (e) {
    console.log(e);
    return errorHandler(e);
  }
}
