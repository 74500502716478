import get from "lodash.get";

const state = () => ({
  token: null,
});

const getters = {
  getToken(state) {
    return get(state, "token", {});
  },
};

const mutations = {
  setToken(state, value) {
    state.token = value;
  },
  reset(state) {
    
    // -----------监测token
    const tokenObj = window.localStorage.getItem("tokenHistory");
    const deviceId = window.localStorage.getItem("asomsDeviceId");

    const tokenData = tokenObj ? JSON.parse(tokenObj) : [];
    tokenData.push({ 
      txt: '手动清空 - 清空token',
      enTxt: 'Manual Clear - Clear Token',
      date: new Date().toLocaleString(), 
      url: window.location.href.split('#')[1],
      asomsDeviceId: deviceId || null,
      token: null,
    })
    window.localStorage.setItem("tokenHistory", JSON.stringify(tokenData) );
    // -----------监测token
     
    state.token = null;
  },
};

const actions = {
  setToken: async ({ commit }, value) => {
    commit("setToken", value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
